import enquire from "enquire.js";

import LandingPagesUI from "@app/modules/core/vanilla/landing-pages-ui";
import type { Tracker } from "@app/tracking/tracker";

import { clickMenu } from "../../../tracking/authentication-tracking";

export class UI {
  public static init(window: Window) {
    return new UI(window);
  }

  private mq: string = "small";
  private head: HTMLHeadElement;
  private body: HTMLElement;
  private main: Element | null;
  private media_queries: Record<
    string,
    {
      rule: string;
      breakpoint: number;
      active: boolean;
    }
  > = {};
  private tracker: Tracker;
  private active_view: HTMLElement | null = null;
  private landing_page_ui: LandingPagesUI;

  public constructor(private window: Window) {
    this.head = window.document.head;
    this.body = window.document.body;
    this.main = window.document.querySelector("#main-view");

    this.tracker = this.window.tracker;

    this.landing_page_ui = new LandingPagesUI(window);

    this._initializeMediaQueryEvents();
    this._initializeFooterSelect();
    this._initializeViews();
    this._initHeader();
    this._initFooter();
  }

  private _initializeMediaQueryEvents() {
    window.requestAnimationFrame(() => {
      const foundation_mq_regex = /^[/\\'"]+|(;\s?})+|[/\\'"]+$/g;

      // keep track of our media queries and which ones are active
      // we are simpliying things a bit and only using the $[size]-up queries
      ["small", "medium", "large"].forEach(mq_name => {
        // append meta tag -- styles set by foundation to include mq settings
        const meta = this.window.document.createElement("meta");
        meta.classList.add(`foundation-mq-${mq_name}`);
        // append() is not support on IE
        this.head.appendChild(meta);

        const computedStyle = this.window.getComputedStyle(meta);
        this.media_queries[mq_name] = {
          rule: computedStyle.fontFamily.replace(foundation_mq_regex, ""),
          breakpoint: parseInt(computedStyle.width),
          active: false
        };
        enquire.register(this.media_queries[mq_name].rule, {
          match: () => {
            this.media_queries[mq_name].active = true;
            this._updateActiveMq();
          },
          unmatch: () => {
            this.media_queries[mq_name].active = false;
            this._updateActiveMq();
          }
        });
      });
    });
  }

  private _initializeFooterSelect() {
    this.landing_page_ui.initializeFooterSelect();
  }

  ///////////////////////////////
  // Fullscreen Views
  ///////////////////////////////
  private _initializeViews() {
    this.body.addEventListener("mediaquerychange:from:small", () =>
      this.hideActiveView()
    );
  }

  private _initHeader() {
    this.landing_page_ui.initHeader();

    const header_signin_button = this.window.document.querySelector(
      "#header-signin-button"
    );
    const signin_button = this.window.document.querySelector("#signin-button");

    if (header_signin_button) {
      header_signin_button.addEventListener("click", () => {
        this.tracker.track(clickMenu("signin", "header"));
        this._dispatchEvent();
      });
    }

    if (signin_button) {
      signin_button.addEventListener("click", () => {
        this.tracker.track(clickMenu("signin", "header"));
        this._dispatchEvent();
      });
    }
  }

  private _initFooter() {
    const footer_signin_buttons = this.window.document.getElementsByClassName(
      "footer-signin-button"
    );
    const footer_account_buttons = this.window.document.getElementsByClassName(
      "footer-account-button"
    );

    for (const footer_signin_button of Array.from(footer_signin_buttons)) {
      footer_signin_button.addEventListener("click", () => {
        this.tracker.track(clickMenu("signin", "footer"));
        this._dispatchEvent();
      });
    }

    for (const footer_account_button of Array.from(footer_account_buttons)) {
      footer_account_button.addEventListener("click", () => {
        this.tracker.track(clickMenu("account", "footer"));
      });
    }
  }

  private _updateActiveMq() {
    const last_mq = this.mq;
    Object.keys(this.media_queries).forEach(name => {
      const mq = this.media_queries[name];
      if (mq.active) {
        this.mq = name;
      }
    });
    // trigger some listenable events for the change
    if (last_mq !== this.mq) {
      this.body.dispatchEvent(new CustomEvent("mediaquerychange"));
      this.body.dispatchEvent(
        new CustomEvent("mediaquerychange:from:" + last_mq)
      );
      this.body.dispatchEvent(
        new CustomEvent("mediaquerychange:to:" + this.mq)
      );
    }
  }

  public hideActiveView() {
    if (!this.active_view) {
      return;
    }
    const hiding_view = this.active_view;
    hiding_view.classList.remove("is-visible");

    setTimeout(() => {
      hiding_view.style.display = "none";
    }, 500);
  }

  public showView(view: HTMLElement) {
    this.active_view = view;

    // just to make sure its within the view container
    // this applies if the view is dynamically created
    this.body.appendChild(view);

    // views start out hidden, then are repositioned offscreen
    // this shows it and adds is-visible which brings it onscreen
    view.style.display = "block";
    setTimeout(() => {
      view.classList.add("is-visible");
      this.main?.classList.add("is-locked");
    }, 0);

    // rebind close button
    view
      .querySelector(".view-close-button")
      ?.addEventListener("click", () => this.hideActiveView());

    // TODO: figure out reusable way to do first-focus within the view
    view.addEventListener(
      "transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",
      () => {
        this.body.querySelector<HTMLElement>("#from")?.focus();
      }
    );
  }

  private _dispatchEvent() {
    if (this.window.store) {
      this.window.store.dispatch({
        type: "modals/openModal",
        payload: { type: "authentication" }
      });
      return false;
    }
  }
}

export default UI;
