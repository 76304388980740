import "./includes/search";
import "./includes/core-vanilla";

import React from "react";

import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { ModalManagerLanding } from "@app/components/modals";
import { SearchFormClient } from "@app/components/search-form/search-form-client";
import { ReactQueryProvider } from "@app/context/react-query-provider";
import { ThemeProviderClient } from "@app/context/theme-provider-client";
import { LiteServiceProviderClient } from "@app/services/context-providers/lite-service-provider-client-base";

(function hydrateErrorPage() {
  const root_id = "header-search";
  const react_content = document.getElementById(root_id);

  if (!react_content) {
    return;
  }

  hydrateRoot(
    react_content,
    <ThemeProviderClient>
      <Provider store={window.store}>
        <LiteServiceProviderClient BB={window.BB} I18N={window.__I18N__}>
          <ReactQueryProvider>
            <>
              <SearchFormClient />
              <ModalManagerLanding />
            </>
          </ReactQueryProvider>
        </LiteServiceProviderClient>
      </Provider>
    </ThemeProviderClient>
  );
})();
