import React, { useLayoutEffect } from "react";

import ThemeProvider from "@busbud/design-system-components/dist/styles/ThemeProvider";
import theme_bb from "@busbud/design-system-components/dist/themes/busbud";
import { Theme } from "@busbud/design-system-components/dist/types";

import { TSSProvider } from "@app/components/tss/tss";
import { HorizonContextProvider } from "@app/context/horizon-context";
import { getDSCLThemeFromWhitelabel } from "@app/helpers/theme";

const use_external_svg = ["production", "test"].includes(
  String(globalThis.__ENV__)
);

interface Props {
  theme?: Theme;
  children: React.ReactNode;
}

export const ThemeProviderClient: React.FC<Props> = ({
  children,
  theme: theme_prop
}) => {
  const [theme_ready_on_hydrate] = React.useState(
    window.BB.whitelabel_theme !== undefined
  );
  const [theme, setTheme] = React.useState(
    theme_prop || window.BB.whitelabel_theme || theme_bb
  );

  const env = typeof window !== "undefined" ? window.BB.config.env : null;

  useLayoutEffect(() => {
    if (theme_ready_on_hydrate) {
      return;
    }
    const updateTheme = async () => {
      const hydrated_theme = await getDSCLThemeFromWhitelabel(
        window.BB.whitelabel
      );
      if (hydrated_theme) {
        setTheme(hydrated_theme);
      }
    };

    updateTheme();
  }, [theme_ready_on_hydrate, theme_prop]);

  return (
    <TSSProvider>
      <ThemeProvider
        theme={theme}
        options={{ useExternalSvgResources: use_external_svg }}
      >
        <HorizonContextProvider environment={env}>
          {children}
        </HorizonContextProvider>
      </ThemeProvider>
    </TSSProvider>
  );
};

export default ThemeProviderClient;
